






























import { Component, Vue } from "vue-property-decorator";
import DataTableSelectAll from "@/components/data-table-select-all.vue";
import { PropType } from "vue";
import _omit from "lodash/omit";
import { DataTableHeader } from "vuetify";

export type DataTableHeaders = {
  [K in "headers" | "expandedHeaders"]: DataTableHeader[];
};

type FilterFunction = <T>(value: T, index: number, array: T[]) => boolean;

const IrisDataTableProps = Vue.extend({
  inheritAttrs: false,
  props: {
    loading: {
      type: [Boolean, String],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Function as PropType<FilterFunction | null>,
      default: null,
    },
    serverItemsLength: {
      type: Number,
      default: -1,
    },
  },
});
@Component({
  components: {
    DataTableSelectAll,
  },
})
export default class IrisDataTable extends IrisDataTableProps {
  get listeners(): Record<string, unknown> {
    return _omit(this.$listeners, ["input"]);
  }
  currentItems = [];
  get filteredItems() {
    // use filter only for local data -> this.serverItemsLength <= -1
    if (this.filter && this.serverItemsLength <= -1) {
      return this.items.filter(this.filter);
    }
    return this.items;
  }
  get model() {
    return this.value;
  }
  set model(value: unknown) {
    this.$emit("input", value);
  }
}
