




























import { Component, Vue } from "vue-property-decorator";
import IrisDataTable from "./iris-data-table.vue";

export type TableRow = {
  address: string;
  endTime: string;
  extID: string;
  code: string;
  generatedTime: string;
  lastChange: string;
  name: string;
  startTime: string;
  status: string;
  statusColor: string;
  statusName: string;
};

// Define the props by using Vue's canonical way.
const EventTrackingListProps = Vue.extend({
  props: {
    tableRowData: Array,
  },
});

@Component({
  components: {
    IrisDataTable,
  },
})
export default class EventTrackingList extends EventTrackingListProps {
  tableData = {
    search: "",
    headers: [
      {
        text: "Ext.ID",
        align: "start",
        value: "extID",
      },
      { text: "Event", value: "name" },
      { text: "Ort", value: "address" },
      { text: "Zeit (Start)", value: "startTime" },
      { text: "Zeit (Ende)", value: "endTime" },
      { text: "Generiert", value: "generatedTime" },
      // { text: "Status", value: "status" },
      // { text: "Letzte Ändrung", value: "lastChange" },
      { text: "", value: "actions" },
    ],
  };

  // TODO improve this - we need it to circumvent v-slot eslint errors
  // https://stackoverflow.com/questions/61344980/v-slot-directive-doesnt-support-any-modifier
  get itemStatusSlotName(): string {
    return "item.status";
  }

  get itemActionSlotName(): string {
    return "item.actions";
  }
}
