
























import { Component, Vue } from "vue-property-decorator";

const DataTableSelectAllProps = Vue.extend({
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
});
@Component
export default class DataTableSelectAll extends DataTableSelectAllProps {
  observer: IntersectionObserver | null = null;
  get partialSelection(): boolean {
    return this.value.length > 0 && this.value.length < this.items.length;
  }
  tooltipEnabled = true;
  tooltipTarget: Element | null = null;
  mounted() {
    this.tooltipTarget = this.$el.closest(".v-data-table") || null;
    if (this.tooltipTarget) {
      this.observer = new IntersectionObserver(() => {
        this.tooltipEnabled = false;
        window.setTimeout(() => {
          this.tooltipEnabled = true;
        }, 500);
      });
      this.observer.observe(this.tooltipTarget);
    }
  }
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.tooltipTarget = null;
  }
  toggle() {
    if (this.value.length <= 0) {
      this.$emit("input", this.currentItems);
    } else {
      if (this.value.length < this.items.length) {
        this.$emit("input", this.items);
      } else {
        this.$emit("input", []);
      }
    }
  }
}
