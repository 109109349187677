


































































import { Component, Vue } from "vue-property-decorator";
import { UserRole } from "@/api";

const UserMenuProps = Vue.extend({
  props: {
    displayName: {
      type: String,
      default: "",
    },
    role: {
      type: String as () => UserRole | undefined,
    },
    id: {
      type: String,
      default: "",
    },
  },
});
@Component
export default class UserMenu extends UserMenuProps {
  logoutConfirmDialog = false;
  logout(confirmed: boolean): void {
    this.logoutConfirmDialog = !confirmed;
    if (confirmed) {
      this.$emit("logout");
    }
  }
  get isAdmin(): boolean {
    return this.role === UserRole.Admin;
  }
  get isUser(): boolean {
    return this.role === UserRole.User;
  }
}
