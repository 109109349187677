



























import { Component, Vue } from "vue-property-decorator";
import { menuEnabled } from "@/router";

const AppSettingsMenuProps = Vue.extend({
  inheritAttrs: false,
});
@Component
export default class AppSettingsMenu extends AppSettingsMenuProps {
  menuEnabled = menuEnabled;
}
