









































































import Vue from "vue";
import { routes, setInterceptRoute } from "@/router";
import UserMenu from "@/views/user-login/components/user-menu.vue";
import { RouteConfig } from "vue-router";
import { UserRole } from "@/api";
import AppSettingsMenu from "@/components/app-settings-menu.vue";

// @todo: move user functionality to a dedicated user-module?
export default Vue.extend({
  name: "App",
  components: {
    AppSettingsMenu,
    UserMenu,
  },
  created() {
    document.title = "IRIS connect";
  },
  data: () => ({
    links: routes,
    showMenu: false,
  }),
  computed: {
    authenticated(): boolean {
      return this.$store.getters["userLogin/isAuthenticated"];
    },
    userDisplayName(): string {
      return this.$store.getters["userLogin/userDisplayName"];
    },
    userID(): string {
      return this.$store.state.userLogin.user?.id ?? "";
    },
    userRole(): UserRole | undefined {
      return this.$store.state.userLogin.user?.role;
    },
  },
  watch: {
    authenticated: {
      immediate: true,
      handler(newValue, oldValue) {
        /**
         * watch "authenticated" is triggered
             if newValue === true
                we fetch the user information and do nothing else
             if newValue === false
                this can be caused by one of the following cases:
                   the token is invalid (401 or 403) -> session expires
                     newValue === false, oldValue === true & authenticationError
                       we store the intercepted route
                       we redirect the user to the login screen
                   the user clicks the logout button
                     newValue === false, oldValue === true & no authenticationError
                       we redirect the user to the login screen
                   watch: immediate is triggered on page load even if there isn't any change
                     newValue === false, oldValue !== true
                       we do nothing
         */
        if (newValue) {
          this.$store.dispatch("userLogin/fetchAuthenticatedUser");
        } else {
          if (oldValue === true) {
            if (this.$store.state.userLogin.authenticationError) {
              // this is triggered if an existing session expires (caused by API response status codes 401 and 403).
              setInterceptRoute(this.$router.currentRoute);
            }
            this.$store.commit("userLogin/setUser");
            this.$router.push("/user/login");
          }
        }
      },
    },
  },
  methods: {
    isLinkDisabled(link: RouteConfig): boolean {
      // @todo - indexTracking: remove disabled check once index cases are permanently activated again
      if (
        link.name === "index-new" ||
        link.name === "index-list" ||
        link.name === "index-details"
      ) {
        if (!this.$store.state.indexTrackingSettings.indexTrackingEnabled) {
          return true;
        }
      }
      return link.meta?.disabled;
    },
    logoutUser() {
      this.$store.dispatch("userLogin/logout");
    },
  },
});
